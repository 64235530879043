














import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OSmsList: () =>
      import("@/components/organisms/event/modules/alertModule/o-sms-list.vue"),
  },
  setup() {
    return {};
  },
});
